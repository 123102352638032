import styled from "styled-components"
import { layout, space } from "styled-system"
import Img from "gatsby-image/withIEPolyfill"
import Grid from "./grid"
import DefaultText from "./text"

export const Container = styled(Grid.Item)`
  display: grid;
`
Container.defaultProps = {
  gridColumn: "main-start / main-end",
  gridColumnGap: ["1.6rem", "2.4rem", "3.2rem"],
  gridTemplateColumns: ["repeat(4, 1fr)", "repeat(8, 1fr)", "repeat(12, 1fr)"],
  px: ["x.0", "x.3", "x.0"],
  pb: ["y.mobile.2.0", "y.desktop.2.0"],
}

export const Testimonial = styled(Grid.Item)`
  display: flex;
  flex-flow: column;
`
Testimonial.defaultProps = {
  gridColumn: ["span 2", "span 4"],
  mb: ["y.mobile.4.0", "y.desktop.4.0"],
}

export const Image = styled(Img)`
  align-self: stretch;
  user-select: none;
  ${layout}
  ${space}
`
Image.defaultProps = {
  height: ["heights.mobile.4.0", "heights.desktop.4.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export const Text = styled(DefaultText)`
  display: block;
`
Text.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.0", "desktop.0"],
  color: "white",
}
