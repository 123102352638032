import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { withPreview } from "gatsby-source-prismic"

import PageWrapper from "../components/page-wrapper"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Text from "../components/text"
import Hero from "../components/client-hero"
import Testimonials from "../components/testimonials"

export const query = graphql`
  query Clients {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicClients {
      edges {
        node {
          prismicId
          data {
            headline {
              text
            }
            description {
              text
            }
            testimonial {
              client {
                document {
                  ... on PrismicClient {
                    id
                    data {
                      name
                      image {
                        fluid(maxHeight: 112) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                  }
                }
              }
              description {
                text
              }
            }
          }
        }
      }
    }
  }
`

const ClientsPage = (props) => {
  const page = get(props, `data.allPrismicClients.edges[0].node.data`)
  if (!page) return null

  return (
    <PageWrapper name="clients">
      <SEO title="Clients" />
      <Grid bg="black.0">
        <Hero>
          <Text.H1 variant="dark">{page.headline.text}</Text.H1>
          <Text.P
            variant="dark"
            textAlign="center"
            fontSize={["mobile.0", "desktop.1"]}
            lineHeight={["mobile.1.5", "desktop.1.5"]}
            my="0"
          >
            {page.description.text}
          </Text.P>
        </Hero>
        <Testimonials data={page.testimonial} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(ClientsPage)
