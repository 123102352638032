import React from "react"
import { RichText } from "prismic-reactjs"
import { Container, Testimonial, Image, Text } from "./testimonials.styles"

export default ({ data }) => (
  <Container>
    {data.map(({ client, description }) => (
      <Testimonial key={client.document.id}>
        <Image
          fluid={client.document.data.image.fluid}
          objectFit="contain"
          alt={client.document.data.image.alt}
        />
        <Text>{description.text}</Text>
      </Testimonial>
    ))}
  </Container>
)
