import styled from "styled-components"
import { space } from "styled-system"
import Grid from "./grid"

export const Container = styled(Grid.Item)`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
`
Container.defaultProps = {
  gridColumn: "full-start / full-end",
  pt: ["y.mobile.4.0", "y.desktop.6.0"],
  pb: ["y.mobile.4.0", "y.desktop.6.0"],
  bg: "black.0",
}

export const Inner = styled.div`
  ${space}
  text-align: center;
`
Inner.defaultProps = {
  mx: ["x.2"],
}
